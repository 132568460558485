import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Navigation } from "swiper";
import { SRLWrapper } from "simple-react-lightbox";
import galleries from "../../../data/GalleryReade";

// install Swiper modules
SwiperCore.use([Navigation]);

function TrackerGallaryWrapper() {
  const gallarySlideOne = {
    slidesPerView: 1,
    speed: 1000,
    spaceBetween: 24,
    loop: true,
    centeredSlides: true,
    roundLengths: true,
    autoplay: {
      delay: 9000,
    },
    navigation: {
      nextEl: ".gallary-next1",
      prevEl: ".gallary-prev1",
    },

    breakpoints: {
      480: {
        slidesPerView: 2,
      },
      768: {
        slidesPerView: 2,
      },
      992: {
        slidesPerView: 3,
      },
      1200: {
        slidesPerView: 3,
      },
    },
  };
  const gallaryslidertwo = {
    slidesPerView: 1,
    speed: 1000,
    spaceBetween: 24,
    loop: true,
    centeredSlides: true,
    roundLengths: true,
    autoplay: {
      delay: 12000,
    },
    navigation: {
      nextEl: ".gallary-next2",
      prevEl: ".gallary-prev2",
    },
    breakpoints: {
      480: {
        slidesPerView: 2,
      },
      768: {
        slidesPerView: 2,
      },
      992: {
        slidesPerView: 3,
      },
      1200: {
        slidesPerView: 3,
      },
    },
  };

  return (
    <>
      <SRLWrapper>
        <div className="gallary-wrapper">
          <div className="container">
            <div className="gallary-group pt-110">
              <div className="gallary-group-header">
                <h4 className="gallary-group-title">Tour Gallery</h4>
                <div className="gallary-arrows text-center d-lg-block d-none">
                  <div
                    className="gallary-button-prev gallary-prev1"
                    tabIndex={0}
                    role="button"
                    aria-label="Previous slide"
                  >
                    <i className="bi bi-chevron-double-left" />
                  </div>
                  <div
                    className="gallary-button-next gallary-next1"
                    tabIndex={0}
                    role="button"
                    aria-label="Next slide"
                  >
                    <i className="bi bi-chevron-double-right" />
                  </div>
                </div>
              </div>
              <div className="gallery-wrapper">
                <div className="row">
                  <Swiper
                    {...gallarySlideOne}
                    className="swiper  gallary-slider"
                  >
                    <div className="swiper-wrapper">
                      <SwiperSlide className="swiper-slide">
                        <div className="gallary-item">
                          <img className="gallary_hw"
                            src={ process.env.PUBLIC_URL +"/gallery/1.JPG"}
                            alt="ImageGallery"/>
                          <a href={process.env.PUBLIC_URL +"/gallery/1.JPG"}
                            className="gallary-item-overlay">
                          <img src={  process.env.PUBLIC_URL +"/gallery/1.JPG"} alt="ImageGallery"/>
                          </a>
                        </div>
                        <div className="gallary-item">
                         <img className="gallary_hw"
                            src={ process.env.PUBLIC_URL +"/gallery/2.JPG"}
                            alt="ImageGallery"/>
                          <a href={process.env.PUBLIC_URL +"/gallery/2.JPG"}
                            className="gallary-item-overlay">
                          <img src={  process.env.PUBLIC_URL +"/gallery/2.JPG"} alt="ImageGallery"/>
                          </a>
                        </div>
                        <div className="gallary-item">
                        <img className="gallary_hw"
                            src={ process.env.PUBLIC_URL +"/gallery/3.JPG"}
                            alt="ImageGallery"/>
                          <a href={process.env.PUBLIC_URL +"/gallery/3.JPG"}
                            className="gallary-item-overlay">
                          <img src={  process.env.PUBLIC_URL +"/gallery/3.JPG"} alt="ImageGallery"/>
                          </a>
                        </div>
                      </SwiperSlide>
                      <SwiperSlide className="swiper-slide">
                        <div className="gallary-item">
                          <img className="gallary_hw"
                              src={ process.env.PUBLIC_URL +"/gallery/4.JPG"}
                              alt="ImageGallery"/>
                            <a href={process.env.PUBLIC_URL +"/gallery/4.JPG"}
                              className="gallary-item-overlay">
                            <img src={  process.env.PUBLIC_URL +"/gallery/4.JPG"} alt="ImageGallery"/>
                            </a>
                        </div>
                        <div className="gallary-item">
                        <img className="gallary_hw"
                            src={ process.env.PUBLIC_URL +"/gallery/5.JPG"}
                            alt="ImageGallery"/>
                          <a href={process.env.PUBLIC_URL +"/gallery/5.JPG"}
                            className="gallary-item-overlay">
                          <img src={  process.env.PUBLIC_URL +"/gallery/5.JPG"} alt="ImageGallery"/>
                          </a>
                        </div>
                        <div className="gallary-item">
                        <img className="gallary_hw"
                            src={ process.env.PUBLIC_URL +"/gallery/6.JPG"}
                            alt="ImageGallery"/>
                          <a href={process.env.PUBLIC_URL +"/gallery/6.JPG"}
                            className="gallary-item-overlay">
                          <img src={  process.env.PUBLIC_URL +"/gallery/6.JPG"} alt="ImageGallery"/>
                          </a>
                        </div>
                      </SwiperSlide>
                      <SwiperSlide className="swiper-slide">
                        <div className="gallary-item">
                        <img className="gallary_hw"
                            src={ process.env.PUBLIC_URL +"/gallery/7.JPG"}
                            alt="ImageGallery"/>
                          <a href={process.env.PUBLIC_URL +"/gallery/7.JPG"}
                            className="gallary-item-overlay">
                          <img src={  process.env.PUBLIC_URL +"/gallery/7.JPG"} alt="ImageGallery"/>
                          </a>
                        </div>
                        <div className="gallary-item">
                        <img className="gallary_hw"
                            src={ process.env.PUBLIC_URL +"/gallery/8.JPG"}
                            alt="ImageGallery"/>
                          <a href={process.env.PUBLIC_URL +"/gallery/8.JPG"}
                            className="gallary-item-overlay">
                          <img src={  process.env.PUBLIC_URL +"/gallery/8.JPG"} alt="ImageGallery"/>
                          </a>
                        </div>
                        <div className="gallary-item">
                        <img className="gallary_hw"
                            src={ process.env.PUBLIC_URL +"/gallery/9.JPG"}
                            alt="ImageGallery"/>
                          <a href={process.env.PUBLIC_URL +"/gallery/9.JPG"}
                            className="gallary-item-overlay">
                          <img src={  process.env.PUBLIC_URL +"/gallery/9.JPG"} alt="ImageGallery"/>
                          </a>
                        </div>
                      </SwiperSlide>
                      <SwiperSlide className="swiper-slide">
                        <div className="gallary-item">
                        <img className="gallary_hw"
                            src={ process.env.PUBLIC_URL +"/gallery/10.JPG"}
                            alt="ImageGallery"/>
                          <a href={process.env.PUBLIC_URL +"/gallery/10.JPG"}
                            className="gallary-item-overlay">
                          <img src={  process.env.PUBLIC_URL +"/gallery/10.JPG"} alt="ImageGallery"/>
                          </a>
                        </div>
                        <div className="gallary-item">
                        <img className="gallary_hw"
                            src={ process.env.PUBLIC_URL +"/gallery/11.JPG"}
                            alt="ImageGallery"/>
                          <a href={process.env.PUBLIC_URL +"/gallery/11.JPG"}
                            className="gallary-item-overlay">
                          <img src={  process.env.PUBLIC_URL +"/gallery/11.JPG"} alt="ImageGallery"/>
                          </a>
                        </div>
                        <div className="gallary-item">
                        <img className="gallary_hw"
                            src={ process.env.PUBLIC_URL +"/gallery/12.JPG"}
                            alt="ImageGallery"/>
                          <a href={process.env.PUBLIC_URL +"/gallery/12.JPG"}
                            className="gallary-item-overlay">
                          <img src={  process.env.PUBLIC_URL +"/gallery/12.JPG"} alt="ImageGallery"/>
                          </a>
                        </div>
                      </SwiperSlide>
                      <SwiperSlide className="swiper-slide">
                        <div className="gallary-item">
                        <img className="gallary_hw"
                            src={ process.env.PUBLIC_URL +"/gallery/13.JPG"}
                            alt="ImageGallery"/>
                          <a href={process.env.PUBLIC_URL +"/gallery/13.JPG"}
                            className="gallary-item-overlay">
                          <img src={  process.env.PUBLIC_URL +"/gallery/13.JPG"} alt="ImageGallery"/>
                          </a>
                        </div>
                        <div className="gallary-item">
                        <img className="gallary_hw"
                            src={ process.env.PUBLIC_URL +"/gallery/14.JPG"}
                            alt="ImageGallery"/>
                          <a href={process.env.PUBLIC_URL +"/gallery/14.JPG"}
                            className="gallary-item-overlay">
                          <img src={  process.env.PUBLIC_URL +"/gallery/14.JPG"} alt="ImageGallery"/>
                          </a>
                        </div>
                        <div className="gallary-item">
                        <img className="gallary_hw"
                            src={ process.env.PUBLIC_URL +"/gallery/15.JPG"}
                            alt="ImageGallery"/>
                          <a href={process.env.PUBLIC_URL +"/gallery/15.JPG"}
                            className="gallary-item-overlay">
                          <img src={  process.env.PUBLIC_URL +"/gallery/15.JPG"} alt="ImageGallery"/>
                          </a>
                        </div>
                      </SwiperSlide>
                      <SwiperSlide className="swiper-slide">
                        <div className="gallary-item">
                        <img className="gallary_hw"
                            src={ process.env.PUBLIC_URL +"/gallery/16.JPG"}
                            alt="ImageGallery"/>
                          <a href={process.env.PUBLIC_URL +"/gallery/16.JPG"}
                            className="gallary-item-overlay">
                          <img src={  process.env.PUBLIC_URL +"/gallery/16.JPG"} alt="ImageGallery"/>
                          </a>
                        </div>
                        <div className="gallary-item">
                        <img className="gallary_hw"
                            src={ process.env.PUBLIC_URL +"/gallery/17.JPG"}
                            alt="ImageGallery"/>
                          <a href={process.env.PUBLIC_URL +"/gallery/17.JPG"}
                            className="gallary-item-overlay">
                          <img src={  process.env.PUBLIC_URL +"/gallery/17.JPG"} alt="ImageGallery"/>
                          </a>
                        </div>
                        <div className="gallary-item">
                        <img className="gallary_hw"
                            src={ process.env.PUBLIC_URL +"/gallery/18.JPG"}
                            alt="ImageGallery"/>
                          <a href={process.env.PUBLIC_URL +"/gallery/18.JPG"}
                            className="gallary-item-overlay">
                          <img src={  process.env.PUBLIC_URL +"/gallery/18.JPG"} alt="ImageGallery"/>
                          </a>
                        </div>
                      </SwiperSlide>
                    </div>
                  </Swiper>
                </div>
              </div>
            </div>
            
          </div>
        </div>
      </SRLWrapper>
    </>
  );
}
export default TrackerGallaryWrapper;
