import React, { useEffect, useReducer } from "react";
import { useState } from "react";
import { Link } from "react-router-dom";
import contactUsData from '../data/ContactUsData'

const TrackerHeader = () => {

  const [searchActive, setSearchActive] = useState(0);
  const [catagoryActive, setCatagoryActive] = useState(0);
  const [userDropdown, setuserDropdown] = useState(0);
  const [mobileSideberMenu, setMobileSideberMenu] = useState(0);

  useEffect(() => {
    // Add scroll event when the component is loaded
    window.addEventListener("scroll", isSticky);
    return () => {
      window.removeEventListener("scroll", isSticky);
    };
  });
  /* Method that will fix header after a specific scrollable */
  const isSticky = (e) => {
    const header = document.querySelector("header");
    const scrollTop = window.scrollY;
    scrollTop >= 200
      ? header.classList.add("sticky")
      : header.classList.remove("sticky");
  };

  const initialState = { activeMenu: "" };
  const [state, dispatch] = useReducer(reducer, initialState);

  function reducer(state, action) {
    switch (action.type) {
      case "homeOne":
        return { activeMenu: "homeOne" };
      case "page":
        return { activeMenu: "page" };
      case "package":
        return { activeMenu: "package" };
      case "blog":
        return { activeMenu: "blog" };
      case "destination":
        return { activeMenu: "destination" };
      default:
        return { activeMenu: " " };
    }
  }
  const scrollTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };
  const handleSearhBar = () => {
    if (searchActive === false || searchActive === 0) {
      setSearchActive(1);
    } else {
      setSearchActive(false);
    }
  };
  const handleCatagorybtn = () => {
    if (catagoryActive === false || catagoryActive === 0) {
      setCatagoryActive(1);
    } else {
      setCatagoryActive(false);
    }
  };
  const handleUserDropDown = () => {
    if (userDropdown === false || userDropdown === 0) {
      setuserDropdown(1);
    } else {
      setuserDropdown(false);
    }
  };
  const handleMobileSiderbar = () => {
    if (mobileSideberMenu === false || mobileSideberMenu === 0) {
      setMobileSideberMenu(1);
    } else {
      setMobileSideberMenu(false);
    }
  };


  return (
    <>
       <header>
         <div className="header-area header-style-three position-absolute w-100">
          <div className="container-fluid">
            <div className="row">
              <div className="col-xxl-2 col-xl-2 col-lg-12 col-md-12 col-sm-12 col-xs-12 align-items-center d-xl-flex d-lg-block">
                <div className="nav-logo d-flex justify-content-between align-items-center ">
                  <a href="/">
                    <img
                      className="nav-logo_header"
                      src={process.env.PUBLIC_URL + "/images/logo-w.png"}
                      alt="logo"
                    />
                  </a>
                  <div className="mobile-menu d-flex ">
                    <div className="d-flex align-items-center">
                      <div className="nav-right-icons d-xl-none d-flex align-items-center ">
                      </div>
                      <Link
                        to={"#"}
                        className={
                          mobileSideberMenu === 1
                            ? "hamburger d-block d-xl-none h-active"
                            : "hamburger d-block d-xl-none"
                        }
                        onClick={handleMobileSiderbar}
                      >
                        <span className="h-top" />
                        <span className="h-middle" />
                        <span className="h-bottom" />
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xxl-7 col-xl-8 col-lg-9 col-md-8 col-sm-6 col-xs-6">
                <nav
                  className={
                    mobileSideberMenu === 1 ? "main-nav slidenav" : "main-nav"
                  }
                >
                  <div className="inner-logo d-xl-none text-center">
                    <Link to={"#"}>
                      <img
                        className="nav-logo_header"
                        src={process.env.PUBLIC_URL + "/images/logo-w.png"}
                        alt=""
                      />
                    </Link>
                  </div>
                  <ul>
                    <li>
                       <Link to={"/"}>Home</Link>
                    </li>
                    <li>
                      <Link to={`${process.env.PUBLIC_URL}/about`} onClick={scrollTop}>
                        About Us
                      </Link>
                    </li>
                    <li>
                       <Link to={`${process.env.PUBLIC_URL}/team`} onClick={scrollTop}>
                         Team
                       </Link>
                    </li>
                    <li>
                       <Link to={`${process.env.PUBLIC_URL}/contactus`} onClick={scrollTop}>
                          Contact 
                       </Link>
                    </li>
                    <li>
                       <Link to={`${process.env.PUBLIC_URL}/gallery`} onClick={scrollTop}>
                          Gallery 
                       </Link>
                    </li>
                  </ul>
                  <div className="inner-contact-options d-xl-none">
                    <div className="contact-box-inner">
                      <i className="bi bi-telephone-fill" />{" "}
                      <a href={contactUsData.hotlineNumber}>{contactUsData.hotlineNumber}</a>
                    </div>
                    <div className="contact-box-inner">
                      <i className="bi bi-envelope-fill" />{" "}
                      <a href={`mailto:`+contactUsData.hotlineEmail}>{contactUsData.hotlineEmail}</a>
                    </div>
                  </div>
                </nav>
              </div>
              <div className="col-xxl-3 col-xl-2 col-lg-1">
                <div className="nav-right d-xl-flex d-none">
                  <div className="nav-right-hotline d-xxl-flex d-none">
                    <div className="hotline-icon">
                      <img
                        src={
                          process.env.PUBLIC_URL +
                          "/images/icons/header-phone.svg"
                        }
                        alt=""
                      />
                    </div>
                    <div className="hotline-info">
                      <span>Hot Line Number</span>
                      <h6>
                        <a href={contactUsData.hotlineNumber}>{contactUsData.hotlineNumber}</a>
                      </h6>
                    </div>
                  </div>
                  <div className="nav-right-icons gap-3">
                  </div>
                </div>
              </div>
            </div>
          </div>
         </div>
       </header>
    </>
  )
}

export default TrackerHeader