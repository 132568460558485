
import React from 'react'
import topDestination from '../data/TopDestination.js'

const TrackerTopDestination = () => {
  return (
    <>
            <div className="destination-area destination-style-two pt-110 pb-110">
               <div className="container">
                  <div className="row justify-content-center">
                        <div className="col-lg-8 col-sm-10 ">
                        <div className="section-head-alpha text-center mx-auto">
                        <h2>Top Destination</h2>
                        </div>
                        </div>
                  </div>
                  <div className="row d-flex justify-content-center g-4">

                    {
                      topDestination.map(destination => (
                        <div className={destination.className} key={destination.id}>
                           <DestinationItem image={destination.image} title={destination.name} place="" />    
                        </div>
                      ))
                    }

                      
                  </div>
               </div>
            </div> 
    </>
  )
}

function DestinationItem(props){
      return(
        <>
        <div className="destination-item">
                    <div className="destination-img">
                      <img src={`${process.env.PUBLIC_URL} ${props.image}`} alt="designation img" />
                    </div>
                    <div className="destination-overlay">
                      <div className="content">
                        <a href="destination-details.html"><h5>{props.title}</h5></a>
                        <a href="destination-details.html"><h6>{props.place}</h6></a>
                      </div>
                    </div>
                  </div>
        </>
      )
}

export default TrackerTopDestination