import React from 'react'
import { Link } from 'react-router-dom'
import { CCard,CRow,CCardImage,CCardTitle, CCardBody, CCardText, CCol } from '@coreui/bootstrap-react';


const Team2 = () => {
  return (
    <>
      <div className="guide-wrapper  pt-110">
        <div className="container">
           <TeamMemeberLeft/>
           <TeamMemeberRight/>
        </div>
      </div>

    </>
  )
}
function PackageCardBeta(props) {
  return (
    <>
      <div className="package-card-beta">
        
        <div className="package-thumb">
          <a href="package-details.html"><img src={`${process.env.PUBLIC_URL} ${props.image}`} alt="package images" /></a>
        </div>
        <div className="package-card-body">
          <h3 className="p-card-title">
            <Link to={`${process.env.PUBLIC_URL}/package-details`} >{props.title}</Link></h3>

        </div>
      </div>
    </>
  )
}

function TeamMemeberRight() {
  return (
    <>
      <CCard className="mb-3">
        <CRow className="g-0">
          <CCol md={8}>
            <CCardBody>
              <CCardText>
               <h4 className="p-card-title">
                 I’m Salman, a 15 years Omani experienced tour guide. Happy to welcome you in my country and to be your bridge to experience Oman in a unique way, glad to share all my knowledge and my passion to my beautiful country through my touring or adventuring trips.
               </h4>
              </CCardText>
             
            </CCardBody>
          </CCol>
          <CCol md={4}>
            <CCardImage src="/img/team/mom2.jpg" />
          </CCol>
        </CRow>
      </CCard>
    </>
  )
}

function TeamMemeberLeft() {
  return (
    <>
      <CCard className="mb-3">
        <CRow className="g-0">
          <CCol md={4}>
            <CCardImage src="/img/team/lina.jpeg" />
          </CCol>
          <CCol md={8}>
            <CCardBody>
              <CCardText>
               <h4 className="p-card-title">
                   I’m Lina, I’m a certified tour guide and happy to welcome you and to share my love to Oman through Oman trekkers . Oman has a lots to Discover from wadis to historical villages to it’s stunning peaks and golden sands and more of all the kindness and hospitality of its people. I will be introducing all of my knowledge through my adventuring or touring trips. In love with Camping as well.
               </h4>
              </CCardText>
             
            </CCardBody>
          </CCol>
        </CRow>
      </CCard>
    </>
  )
}


export default Team2