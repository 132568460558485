import React from "react";
import { Link } from "react-router-dom";
import contactUsData from "../../data/ContactUsData"

function ContactWrapperArea() {
  return (
    <>
      <div className="contact-wrapper pt-110">
        <div className="container">
          <div className="row align-items-center justify-content-lg-center gy-5">
            <div className="col-lg-6">
              <div className="contatc-intro-figure">
                <img
                  src={process.env.PUBLIC_URL + "/img/contactus/contactus1.jpg"}
                  alt="ContactBannerIMG"
                  className="img-fluid"
                />
              </div>
            </div>
            <div className="col-lg-5">
              <div className="contact-info">
                <h3>Contact Info.</h3>
                <ul>
                  <li>
                    <h6>Let’s Talk</h6>
                    {
                      contactUsData.phones.map(phone =>(
                        <a rel="noopener noreferrer" target="_blank" key={phone.id} >
                           { phone.number}
                        </a>
                      ))
                    }
                    
                  </li>
                  <li>
                    <h6>Email</h6>
                    {
                      contactUsData.emails.map(email =>(
                        <a  key={email.id} >{email.email}</a>
                      ))
                    }
                  </li>
                  <li>
                    <h6>Visit Us.</h6>

                    {
                      contactUsData.socialLinks.map(social =>(
                        <a
                           rel="noopener noreferrer"
                           target="_blank"
                           key={social.id}
                           href={social.link}>
                            <b>{social.media }: </b>{social.displayName}
                           
                        </a>   
                      ))
                    }
                    
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      
      
      </div>
    </>
  );
}

export default ContactWrapperArea;
