import React, { useState, useEffect } from 'react'
import { Link } from "react-router-dom";
import { SRLWrapper } from "simple-react-lightbox";
import contactUsData from "../data/ContactUsData.js"
const TrackerFooter = (props) => {

    // smooth scrol
   const scrollTop = () => {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    };

    const [numbers, setNumbers] = useState("");
    const [emails, setEmails] = useState("");

    useEffect(() => {
      setNumbers(phoneNumbers)
      setEmails(emailsStr)
    }, []);

    const phoneNumbers = () =>  {
      var numberStr = ""
      contactUsData.phones.forEach(phone =>{
        numberStr = numberStr +  phone.number + ` `
      })
      return numberStr
    }; 

    const emailsStr = () => {
      var emailStr = ""
      contactUsData.emails.forEach(email =>{
        emailStr = emailStr +  email.email + ` `
      })
      return emailStr
    };

  return (
    <>
      <div className={props.className}>
          <div className="footer-main-wrapper">
            <div className="footer-vactor">
                  <img src={process.env.PUBLIC_URL + "/img/footer/footer-bg.png"} alt="FooterIMG" />
            </div>
            <div className="container">
                  <div className="row justify-content-center g-4">
                     <div className="col-lg-4">
                        <div className="footer-about text-lg-start text-center">
                              <p> {contactUsData.socialInfo} </p>
                              <div className="footer-social-wrap">
                                    <h5>Follow Us On:</h5>
                                          <ul className="footer-social-links justify-content-lg-start justify-content-center">
                                             {
                                               contactUsData.socialLinks.map(socialLink =>(
                                                <li>
                                                    <a  rel="noopener noreferrer" 
                                                    href={socialLink.link}
                                                    target="_blank">
                                                          <i className={socialLink.className} />
                                                    </a>
                                                </li>
                                               ))
                                             }
                                          </ul>
                              </div>
                        </div>
                     </div>
                     <div className="col-lg-2 col-md-4">
                         <div className="footer-widget">
                           <h3 className="footer-widget-title">Quick Link</h3>
                           <ul className="footer-links">
                              <li>
                               <Link
                                    onClick={scrollTop}
                                    to={`${process.env.PUBLIC_URL}/about`}>
                                    About Us
                               </Link>
                              </li>
                              <li>
                               <Link
                                    onClick={scrollTop}
                                    to={`${process.env.PUBLIC_URL}/team`}>
                                    Team
                               </Link>
                              </li>
                              <li>
                               <Link
                                    onClick={scrollTop}
                                    to={`${process.env.PUBLIC_URL}/contactus`}>
                                    Contact
                               </Link>
                              </li>
                              <li>
                               <Link
                                    onClick={scrollTop}
                                    to={`${process.env.PUBLIC_URL}/gallery`}>
                                    Gallery
                               </Link>
                              </li>
                           </ul>
                         </div>
                     </div>
                     <div className="col-lg-2 col-md-4">
                     <div className="footer-widget">
                        <h4 className="footer-widget-title">Tour Type</h4>
                        <ul className="footer-links">
                              <li>
                                <Link onClick={scrollTop}
                                       to={`${process.env.PUBLIC_URL}/`}>
                                    Wild &amp; Adventure Tours
                                </Link>
                              </li>
                              <li>
                                <Link onClick={scrollTop}
                                       to={`${process.env.PUBLIC_URL}/`}>
                                    Yacth Excursion &amp; Scuba Diving
                                </Link>
                              </li>
                              <li>
                                <Link onClick={scrollTop}
                                       to={`${process.env.PUBLIC_URL}/`}>
                                    Camping &amp; Overnights 
                                </Link>
                              </li>
                              <li>
                                <Link onClick={scrollTop}
                                       to={`${process.env.PUBLIC_URL}/`}>
                                    Hiking &amp; Abseiling 
                                </Link>
                              </li>
                        </ul>
                     </div>
                  </div>
                     <div className="col-lg-4 col-md-8">
                  <SRLWrapper>
                    <div className="footer-widget">
                      <h4 className="footer-widget-title text-center">
                        Gallery
                      </h4>
                      <div className="footer-gallary-grid">
                        <div className="footer-gallary-item">
                          <a
                            href={process.env.PUBLIC_URL + "/gallery/10.JPG"}
                            data-fancybox="footer"
                            data-caption="Caption Here"
                          >
                            <img  className="footer_gallary_hw" src={process.env.PUBLIC_URL + "/gallery/10.JPG"} alt="footerIMG" />
                          </a>
                        </div>
                        <div className="footer-gallary-item">
                        <a
                            href={process.env.PUBLIC_URL + "/gallery/1.JPG"}
                            data-fancybox="footer"
                            data-caption="Caption Here"
                          >
                            <img  className="footer_gallary_hw" src={process.env.PUBLIC_URL + "/gallery/1.JPG"} alt="footerIMG" />
                          </a>
                        </div>
                        <div className="footer-gallary-item">
                        <a
                            href={process.env.PUBLIC_URL + "/gallery/3.JPG"}
                            data-fancybox="footer"
                            data-caption="Caption Here"
                          >
                            <img  className="footer_gallary_hw" src={process.env.PUBLIC_URL + "/gallery/3.JPG"} alt="footerIMG" />
                          </a>
                        </div>
                        <div className="footer-gallary-item">
                        <a
                            href={process.env.PUBLIC_URL + "/gallery/45.JPG"}
                            data-fancybox="footer"
                            data-caption="Caption Here"
                          >
                            <img  className="footer_gallary_hw" src={process.env.PUBLIC_URL + "/gallery/45.JPG"} alt="footerIMG" />
                          </a>
                        </div>
                        <div className="footer-gallary-item">
                        <a
                            href={process.env.PUBLIC_URL + "/gallery/6.JPG"}
                            data-fancybox="footer"
                            data-caption="Caption Here"
                          >
                            <img  className="footer_gallary_hw" src={process.env.PUBLIC_URL + "/gallery/6.JPG"} alt="footerIMG" />
                          </a>
                        </div>
                        <div className="footer-gallary-item">
                          <a
                            href={process.env.PUBLIC_URL + "/gallery/17.JPG"}
                            data-fancybox="footer"
                            data-caption="Caption Here"
                          >
                            <img  className="footer_gallary_hw" src={process.env.PUBLIC_URL + "/gallery/17.JPG"} alt="footerIMG" />
                          </a>
                        </div>
                      </div>
                    </div>
                  </SRLWrapper>
                     </div>
                  </div>  
                  <div className="footer-contact-wrapper">
                        <h5>Contact Us:</h5>
                        <ul className="footer-contact-list">
                              <li>
                                    <i className="bi bi-telephone-x" />{" "} 
                                    <a rel="noopener noreferrer" >
                                       {numbers}
                                    </a>
                              </li>
                              <li>
                                    <i className="bi bi-envelope-open" />{" "}
                                    <a href={emails}>{emails}</a>
                              </li>
                        </ul>
                  </div>    
            </div>
          </div>
          <div className="footer-bottom">
           <div className="container">
                  <div className="row align-items-center justify-content-center">
                        <div className="col-lg-4 col-md-6 order-lg-1 order-3 ">
                        </div>
                        <div className="col-lg-4  order-lg-2 order-1">
                           <div className="footer-logo text-center">
                              <Link onClick={scrollTop} to={`${process.env.PUBLIC_URL}/`}>
                              <img 
                                className="nav-logo_header"
                                src={process.env.PUBLIC_URL + "/images/logo-w.png"}
                                alt="FooterIMG" />
                              </Link>
                           </div>
                        </div>
                        <div className="col-lg-4 col-md-6 order-lg-3 order-2">
                          
                        </div>
                  </div>
           </div>
          </div>   
      </div>
         
    </>
  )
}

export default TrackerFooter