import React from 'react'
import AboutWrapperArea from '../../components/pages/about/AboutWrapperArea'
import TrackerBreadcrumb from '../common/TrackerBreadcrumb'
import Team from './team/Team'

const AboutPage = () => {
  return (
    <>
       <TrackerBreadcrumb name="About" />
       <AboutWrapperArea />
    </>
  )
}

export default AboutPage