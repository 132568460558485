import React from 'react'
import TrackerBreadcrumb from '../common/TrackerBreadcrumb'
import TrackerGallaryWrapper from './gallery/TrackerGallaryWrapper'

const GalleryPage = () => {
  return (
    <>
      <TrackerBreadcrumb name="Gallery" />
      <TrackerGallaryWrapper/>
    </>
  )
}

export default GalleryPage