const topDestination = [
    {
      "id":1,
      "image":"/destination/waid_1.jpg",
      "name":"Wadis",
      "des":"Oman is very famous with its stunning wadies, wadi in Arabic mean valley in English but Oman wadi’s is the mostly the trail between 2 mountains and mostly with one or more of water pools. Wadi Shab is number one wadi in Oman but there is also another hidden wadis that you must visit when to visit Oman. We can arrange day tours to experience 2 wadis in each day.",
      "className":"col-lg-6 col-md-12 col-sm-10 fadeffect"
    },
    {
      "id":2,
      "image":"/destination/t_20.jpg",
      "name":"Turtles",
      "des":"Oman has five of the world’s  seven species of sea turtles: The Green Turtle, nests and found on most Omani beaches but plentiful in Ras Al Hadd, Ras Al Jinz, Masirah Island and Ad Daymaniyat Islands; the Loggerhead Turtle that nests on Masirah Island, the shores of Dhofar and Daymaniyat Islands; the Hawksbill Turtle that nests on the shores of Muscat and Daymaniyat Islands; the Olive Ridley Turtle that nests on Masirah Island; and the Leatherback Turtle which is found in Omani waters but does not nest in the Sultanate.",
      "className":"col-lg-3 col-md-6 col-sm-10 fadeffect"
    },
    {
      "id":3,
      "image":"/destination/wadi_2.jpg",
      "name":"Mountains",
      "des":"You might be tempted to think of Oman as all desert dunes, but in actual fact Oman is also famous with the dramatic Hajar mountain range, tallest among them the legendary Jebel Shams at just over 3,000 metres. These mountains contain some of the Gulf’s wildest habitats, with soaring birds of prey surfing on high-altitude thermals, acacias sprouting small, perfuming flowers in the rocky hillside, and huge, dramatic drops that will send your heart racing. Also in Jabal shams you can enjoy the second largest Grand Canyon in the world, you can either hike through many routes with deferent levels or rappel or via feratta. Jabal Akhdar is also another magnificent mountain in Oman with deferent routes and level to explore the beauty of this mountain. Jabal Samhan also is one of the most famous Omani mountains with its stunning trails.",
      "className":"col-lg-3 col-md-6 col-sm-10 fadeffect"
    },
    {
      "id":4,
      "image":"/destination/heritage_1.jpg",
      "name":"Heritage Villages",
      "des":"MUSCAT: The Muscat Festival continues under the theme “Let’s Celebrate Together” with a variety of events that are suitable for all segments of the society. Among the various events of the festival is the ‘Heritage Village’ at Al Amerat Park, which is a realistic embodiment of the Omani villages, showcasing live scenes that reduce the time and space to discover the richness and diversity of heritage, as well as the popular market in the village.",
      "className":"col-lg-3 col-md-6 col-sm-10 fadeffect"
    },
    {
      "id":5,
      "image":"/destination/muscat_1.jpg",
      "name":"Muscat",
      "des":"Muscat, Oman’s port capital, sits on the Gulf of Oman surrounded by mountains and desert. With history dating back to antiquity, it mixes high-rises and upscale shopping malls with clifftop landmarks such as the 16th-century Portuguese forts, Al Jalali and Mirani, looming over Muscat Harbor",
      "className":"col-lg-3 col-md-6 col-sm-10 fadeffect"
    },
    {
      "id":6,
      "image":"/destination/desert_1.jpg",
      "name":"Desert",
      "des":"The Gulf of Oman desert and semi-desert is a coastal ecoregion on the Persian Gulf and the Gulf of Oman in Oman and the United Arab Emirates at the northeastern tip of the Arabian Peninsula. The climate is hot and dry, with gravelly plains and savanna with thorny acacia trees inland from the coast.",
      "className":"col-lg-6 col-md-12 col-sm-10 fadeffect"
    }
]

export default topDestination