import React from 'react'
import TrackerHeader from '../common/TrackerHeader'
import HomePage from '../pages/HomePage'
import TrackerFooter from '../common/TrackerFooter'
import uniqueValues from '../../data/GalleryReade.js'
const HomeScreen = () => {

  return (
    <>
       <TrackerHeader />
        <HomePage/>
        <TrackerFooter className="footer-area mt-110" />
    </>
  )
}

export default HomeScreen