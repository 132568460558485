import React from 'react'
import Testimonial3 from '../../components/pages/homeThree/Testimonial3'
import HomeHero from './home/HomeHero'
import OmanWrapperArea from './home/OmanWrapperArea'
import TrackerTestimonial from './home/TrackerTestimonial'
import TrackerTopDestination from './TrackerTopDestination'
import TrackerUpComingTour from './TrackerUpComingTour'

const HomePage = () => {
  return (
    <>
      <HomeHero/>
      <OmanWrapperArea/>
      <TrackerTopDestination/>
      <TrackerUpComingTour/>
    </>
  )
}
// <TrackerTestimonial/>
export default HomePage