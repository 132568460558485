import React from 'react'
import TrackerBreadcrumb from '../common/TrackerBreadcrumb'
import TrackerContactWrapperArea from "../pages/contact/TrackerContactWrapperArea"

const ContactUsPage = () => {
  return (
    <div>
      <TrackerBreadcrumb name="Contact" />
      <TrackerContactWrapperArea />
    </div>
  )
}

export default ContactUsPage