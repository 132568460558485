import React from 'react'
import TrackerHeader from '../common/TrackerHeader'
import TrackerFooter from '../common/TrackerFooter'
import TeamPage from '../pages/TeamPage'
const TeamScreen = () => {
  return (
    <>
        <TrackerHeader />
        <TeamPage/>
       <TrackerFooter className="footer-area mt-110" />
    </>
  )
}

export default TeamScreen