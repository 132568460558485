import React from 'react'
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import SimpleReactLightbox from "simple-react-lightbox";
import HomeScreen from "./trackercomponents/screens/HomeScreen"
// import all css
import "./index.css";
import AboutUsScreen from './trackercomponents/screens/AboutUsScreen';
import TeamScreen from './trackercomponents/screens/TeamScreen';
import ContactUsScreen from './trackercomponents/screens/ContactUsScreen';
import GalleryScreen from './trackercomponents/screens/GalleryScreen';


/*
 * Version :Tourx-pro 0.1
 * Event : Rendering all content to web.
 * Actions: Define all routes and page.
 * @return html
 * */
// default Warning Error hide



function Root() {
  return (
    <>
    <BrowserRouter basename="/">
      <Switch>
        {/*main layout*/}
        <Route exact path="/" component={HomeScreen} />
        <Route exact path="/about" component={AboutUsScreen} />
        <Route exact path="/team" component={TeamScreen} />
        <Route exact path="/contactus" component={ContactUsScreen} />
        <Route exact path="/gallery" component={GalleryScreen} />
      </Switch>
    </BrowserRouter>
  </>
  )
}

export default Root
ReactDOM.render(
  <React.StrictMode>
    <SimpleReactLightbox>
      <Root />
    </SimpleReactLightbox>
  </React.StrictMode>,
  document.getElementById("root")
);
