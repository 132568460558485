import React, { useState } from "react";
import ModalVideo from "react-modal-video";
import "react-modal-video/css/modal-video.css";
import aboutUsData from "../../../trackercomponents/data/AboutUsData";
import parse from 'html-react-parser';
function AboutWrapperArea() {
  const [isOpen, setOpen] = useState(false);

  return (
    <>
      <div className="about-main-wrappper pt-110">
        <div className="container">
          <div className="about-tab-wrapper">
            <div className="row justify-content-center">
              <div className="col-lg-6">
                <div className="about-tab-image-grid text-center">
                  <div className="about-video d-inline-block">
                    <img src={process.env.PUBLIC_URL + "/images/about/media.jpg"} alt="aboutIMG" />
                    <div className="video-overlay">
                      <div className="play-icon video-popup">
                        <i
                          onClick={() => setOpen(true)}
                          className="bi bi-play-fill"
                        ></i>
                      </div>
                    </div>
                  </div>
                  <div className="row float-images g-4">
                    <div className="col-lg-6 col-md-6 col-sm-6">
                      <div className="about-image">
                        <img src={process.env.PUBLIC_URL + "/images/about/about-g1.png"} alt="aboutIMG" />
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-6">
                      <div className="about-image">
                        <img src={process.env.PUBLIC_URL + "/images/about/about-g3.png"} alt="aboutIMG" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 mt-5 mt-lg-0">
                <div className="about-tab-wrap">
                  <h2 className="about-wrap-title">
                    {parse(aboutUsData.aboutUs.offer)}
                  </h2>
                  <div className="about-tab-switcher">
                    <ul
                      className="nav nav-pills mb-3 justify-content-md-between justify-content-center"
                      id="about-tab-pills"
                      role="tablist"
                    >
                      <li className="nav-item" role="presentation">
                        <div
                          className="nav-link active"
                          id="pills-about1"
                          data-bs-toggle="pill"
                          data-bs-target="#about-pills1"
                          role="tab"
                          aria-controls="about-pills1"
                          aria-selected="true">
                          {parse(aboutUsData.aboutUs.experience)}
                        </div>
                      </li>
                      <li className="nav-item" role="presentation">
                        <div
                          className="nav-link"
                          id="pills-about2"
                          data-bs-toggle="pill"
                          data-bs-target="#about-pills2"
                          role="tab"
                          aria-controls="about-pills2"
                          aria-selected="false"
                        >
                          <h3>100+</h3>
                          <h6>Tour Guide</h6>
                        </div>
                      </li>
                      <li className="nav-item" role="presentation">
                        <div
                          className="nav-link"
                          id="pills-about3"
                          data-bs-toggle="pill"
                          data-bs-target="#about-pills3"
                          role="tab"
                          aria-controls="about-pills3"
                          aria-selected="false"
                        >
                          <h3>500+</h3>
                          <h6>Travelar Connect</h6>
                        </div>
                      </li>
                    </ul>
                  </div>
                  <div
                    className="tab-content about-tab-content"
                    id="pills-tabContent"
                  >
                    <div
                      className="tab-pane fade show active"
                      id="about-pills1"
                      role="tabpanel"
                      aria-labelledby="pills-about1"
                    >
                      <p>
                      {parse(aboutUsData.aboutUs.desc)}
                      </p>
                    </div>
                    <div
                      className="tab-pane fade"
                      id="about-pills2"
                      role="tabpanel"
                      aria-labelledby="pills-about2"
                    >
                      <p>
                      {parse(aboutUsData.aboutUs.desc)}
                      </p>
                    </div>
                    <div
                      className="tab-pane fade"
                      id="about-pills3"
                      role="tabpanel"
                      aria-labelledby="pills-about3"
                    >
                      <p>
                      {parse(aboutUsData.aboutUs.desc)}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row pt-110">
            <div className="col-lg-6">
              <div className="achievement-counter-wrap">
                <h2 className="about-wrap-title">
                   {parse(aboutUsData.aboutUs.whyChooseUs)}
                </h2>
                <div className="achievement-counter-cards">
                  <div className="achievement-counter-card flex-sm-row flex-column text-sm-start text-center ">
                    <div className="counter-box mb-sm-0 mb-3">
                     {parse(aboutUsData.aboutUs.numberOfTours)}
                    </div>
                    <p>
                     {parse(aboutUsData.aboutUs.numberOfToursDesc)}
                    </p>
                  </div>
                  <div className="achievement-counter-card flex-sm-row flex-column text-sm-start text-center">
                    <div className="counter-box">
                    {parse(aboutUsData.aboutUs.numberOfDestinations)}
                    </div>
                    <p>
                    {parse(aboutUsData.aboutUs.numberOfDestinationsDesc)}
                    </p>
                  </div>
    
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="about-image-group mt-5 mt-lg-0">
                <img src={process.env.PUBLIC_URL + "/images/about/about-image-group2.png"} alt="aboutIMG" className="img-fluid" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <React.Fragment>
        <ModalVideo
          channel="youtube"
          autoplay
          isOpen={isOpen}
          videoId="4Y9yainIQKA"
          onClose={() => setOpen(false)}
        />
      </React.Fragment>
    </>
  );
}

export default AboutWrapperArea;
