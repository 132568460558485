import React from 'react'
import TrackerHeader from '../common/TrackerHeader'
import TrackerFooter from '../common/TrackerFooter'
import AboutPage from '../pages/AboutPage'
const AboutUsScreen = () => {
  return (
    <>
       <TrackerHeader />
       <AboutPage/>
       <TrackerFooter className="footer-area mt-110" />
    </>
  )
}

export default AboutUsScreen