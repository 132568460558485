import React from 'react'
import TrackerHeader from '../common/TrackerHeader'
import TrackerFooter from '../common/TrackerFooter'
import GalleryPage from '../pages/GalleryPage'

const GalleryScreen = () => {
  return (
    <>
       <TrackerHeader />
       <GalleryPage/>
       <TrackerFooter className="footer-area mt-110" />
    </>
  )
}

export default GalleryScreen