import React from "react";
import TrackerGuideCardGama from "./TrackerGuideCardGama";

function TrackerGuideWrap() {
  return (
    <>
      <div className="guide-wrapper  pt-110">
        <div className="container">
          <div className="row g-4">
            <div className="col-lg-4 col-md-6">
              <TrackerGuideCardGama image={process.env.PUBLIC_URL + "/img/team/lina.jpeg"} name="Lina" />
            </div>
            <div className="col-lg-4 col-md-6">
              <TrackerGuideCardGama image={process.env.PUBLIC_URL + "/img/team/mom2.jpg"} name="Mohammad" />
            </div>
          
          </div>
        </div>
      </div>
    </>
  );
}

export default TrackerGuideWrap;
