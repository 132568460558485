import React from 'react'
import TrackerHeader from '../common/TrackerHeader'
import TrackerFooter from '../common/TrackerFooter'
import TeamPage from '../pages/TeamPage'
import ContactUsPage from '../pages/ContactUsPage'
const ContactUsScreen = () => {
  return (
    <>
      <TrackerHeader />
      <ContactUsPage/>
      <TrackerFooter className="footer-area mt-110" />
    </>
  )
}

export default ContactUsScreen