import React from 'react'
import TrackerBreadcrumb from '../common/TrackerBreadcrumb'
import Team from './team/Team'
import Team2 from './team/Team2'
const TeamPage = () => {
  return (
    <>
      <TrackerBreadcrumb name="Team" />
      <Team2/>
    </>
  )
}

export default TeamPage