import React from 'react'
import { Link } from 'react-router-dom'
import SwiperCore, { EffectFade, Navigation, Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import contactUsData from '../../data/ContactUsData';
// install Swiper modules
SwiperCore.use([Navigation, Pagination, EffectFade]);

const HomeHero = () => {

      const hero3Silde = {
            slidesPerView: 1,
            speed: 1500,
            parallax: true,
            loop: true,
            spaceBetween: 0,
            centeredSlides: true,
            roundLengths: true,
            effect: 'fade',
            navigation: {
                  nextEl: '.hero-next3',
                  prevEl: '.hero-prev3',
            },
            autoplay: {
                  delay: 5000
            },
            pagination: {
                  el: ".hero-two-pagination",
                  clickable: true,
                  renderBullet: function (index, className) {
                        return '<span class="' + className + '">' + 0 + (index + 1) + "</span>";
                  }
            }
      }


      return (
            <>
                  <div className="hero-area hero-style-three">
                        <div className="hero-main-wrapper position-relative">
                              <div className="hero-social">
                                    <ul className="social-list d-flex justify-content-center align-items-center gap-4">
                                          {
                                             contactUsData.socialLinks.map(socialLink =>(
                                                <li><a href={socialLink.link} target="_blank" >{socialLink.media}</a></li>
                                             ))    
                                          }
                                    </ul>
                              </div>
                              <Swiper className="swiper  hero-slider-three " {...hero3Silde}>
                                    <div className="swiper-wrapper" >
                                          <SwiperSlide className="swiper-slide">
                                                <div className="slider-bg-1">
                                                      <div className="container">
                                                            <div className="row d-flex justify-content-center align-items-center">
                                                                  <div className="col-lg-8">
                                                                        <div className="hero3-content">
                                                                              <span className="title-top">Wellcome To Oman Trekkers</span>
                                                                              <h1>Journey to Explore Oman</h1>
                                                                              <p>Discover the history & nature of Oman.</p>
                                                                        </div>
                                                                  </div>
                                                            </div>
                                                      </div>
                                                </div>
                                          </SwiperSlide>
                                          <SwiperSlide className="swiper-slide">
                                                <div className="slider-bg-3">
                                                      <div className="container">
                                                            <div className="row d-flex justify-content-center align-items-center">
                                                                  <div className="col-lg-8">
                                                                        <div className="hero3-content">
                                                                              <span className="title-top">Wellcome To Oman Trekkers</span>
                                                                              <h1>Enjoy Your New Adventure</h1>
                                                                              <p>Experience the Beauty and Hospitality of Oman With Us</p>
                                                                        </div>
                                                                  </div>
                                                            </div>
                                                      </div>
                                                </div>
                                          </SwiperSlide>
                                    </div>
                              </Swiper>
                              <div className="slider-arrows text-center d-lg-flex flex-column d-none gap-5">
                                    <div className="hero-prev3" tabIndex={0} role="button" aria-label="Previous slide"> <i className="bi bi-arrow-left" /></div>
                                    <div className="hero-next3" tabIndex={0} role="button" aria-label="Next slide"><i className="bi bi-arrow-right" /></div>
                              </div>
                        </div>
                  </div>
            </>
      )
}

export default HomeHero