import React, { useState } from 'react'
import ModalVideo from "react-modal-video";
import "react-modal-video/css/modal-video.css";
import aboutUsData from '../../data/AboutUsData';

const OmanWrapperArea = () => {

      const [isOpen, setOpen] = useState(false);

  return (
    <>
          <div className="about-main-wrappper pt-110">
        <div className="container">
          <div className="about-tab-wrapper">
            <div className="row justify-content-center">
              <div className="col-lg-6 mt-5 mt-lg-0">
                <div className="about-tab-wrap">
                  <h2 className="about-wrap-title">
                    {aboutUsData.aboutCountry.title}
                  </h2>
                  <div
                    className="tab-content about-tab-content"
                    id="pills-tabContent">
                    <div
                      className="tab-pane fade show active"
                      id="about-pills1"
                      role="tabpanel"
                      aria-labelledby="pills-about1">
                      <p>{aboutUsData.aboutCountry.description}</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="about-tab-image-grid text-center">
                  <div className="about-video d-inline-block">
                    <img src={process.env.PUBLIC_URL + aboutUsData.aboutCountry.videoImge} alt="aboutIMG" />
                    <div className="video-overlay">
                      <div className="play-icon video-popup">
                        <i
                          onClick={() => setOpen(true)}
                          className="bi bi-play-fill"
                        ></i>
                      </div>
                    </div>
                  </div>
                  <div className="row float-images g-4">
                    <div className="col-lg-6 col-md-6 col-sm-6">
                      <div className="about-image">
                        <img src={process.env.PUBLIC_URL + aboutUsData.aboutCountry.imge1} alt="aboutIMG" />
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-6">
                      <div className="about-image">
                        <img src={process.env.PUBLIC_URL + aboutUsData.aboutCountry.imge2} alt="aboutIMG" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
         
        </div>
      </div>
      <React.Fragment>
        <ModalVideo
          channel="youtube"
          autoplay
          isOpen={isOpen}
          videoId={aboutUsData.aboutCountry.videoCode}
          onClose={() => setOpen(false)}
        />
      </React.Fragment>
    </>
  )
}

export default OmanWrapperArea