const contactUsData = {
     "hotlineNumber":"+96893902303",
     "hotlineEmail":"omantrekker@gmail.com",
     "phones":[
         {
            "id":1, 
            "number":"+96895441187",
            "name":"Salman"
         },
         {
            "id":2, 
            "number":"+96893902303  +971502853521",
            "name":"Lina"
         }
     ],
     "emails":[
          {
            "id":1, 
            "email":"omantrekker@gmail.com"
          }
     ],
     "socialInfo":"Memories Made Together Last a Lifetime. Growing community by inspiring healthy is our commitment with farmers, the abundant living on the field.",
     "socialLinks":[
         {
            "id":2, 
            "media":"Facebook",
            "link":"https://www.facebook.com/100089124104216/posts/pfbid031KEmrd2quUSi42sELKyGSFXBYvqJyg3qG3tp1nrTdihGyN8xz9wGEpLVY8ceKQVal/?d=w&mibextid=qC1gEa",
            "className":"bx bxl-facebook",
            "displayName":"www.facebool.com"
         },
         {
            "id":3, 
            "media":"Instagram",
            "link":"https://www.instagram.com/reel/CoTs6lJIW9L/?igshid=MDJmNzVkMjY%3D",
            "className":"bx bxl-instagram",
            "displayName":"www.instagram.com"
         },
         {
            "id":4, 
            "media":"YoutTube",
            "link":"https://www.youtube.com/shorts/4Y9yainIQKA",
            "className":"bx bxl-youtube",
            "displayName":"www.youtube.com"
         }
    ]
}

export default contactUsData